<template>

  <div class="table-row clickable" :class="{ 'is-open': showDetails }" @click="toggleDetails">
    <div class="small">
      <button
        @click.stop="toggleDetails"
        class="toggle-details-button small ghost"
      >
        <ChevronIcon />
      </button>
    </div>
    <div class="icons">
      <img :src="patchEvent.patch.contract.iconUrl" />
      <span>{{ patchEvent.details.contractName }}</span>
    </div>
    <div class="signature-name">
      <Tag :variant="patchEvent.details.signatureType === 'function' ? 'cyan' : 'purple'">
        {{ titleCase(patchEvent.details.signatureType || 'Event') }}
      </Tag>
      <span>{{ patchEvent.details.signatureName }}</span>
    </div>
    <div class="icons">
      <img :src="patchEvent.patch.network.iconUrl" />
      <span>{{ patchEvent.patch.network.name }}</span>
    </div>
    <div>
      <span class="badge gray subtle font-normal">
        {{ patchEvent.patch.contract.type === 'other' ? titleCase(patchEvent.patch.contract.type) : patchEvent.patch.contract.type.toUpperCase() }}
      </span>
    </div>
    <div class="font-mono">
      <Tooltip maxWidth="25rem">
        <template #trigger>
          <span class="inline 2xl:hidden">
            {{ truncateAddress(patchEvent.patch.contract.address) }}
          </span>
        </template>
        <span>{{ patchEvent.patch.contract.address }}</span>
      </Tooltip>
      <span class="hidden 2xl:inline">
        {{ patchEvent.patch.contract.address }}
      </span>
    </div>
    <div class="timestamp">
      {{ formatTimestamp(patchEvent.insertedAt) }}
    </div>
  </div>
  <PatchEventDetails
    :show="showDetails"
    location="patch-history"
    :patchEvent="patchEvent"
  />

</template>

<script>

  import useFilters from '@/composables/useFilters'

  import ChevronIcon from '@/assets/icons/chevron.svg'

  import Tag from '@/components/utils/Tag.vue'
  import Tooltip from '@/components/utils/Tooltip.vue'
  import PatchEventDetails from '@/components/tables/PatchEventDetails.vue'

  export default {
    emits: ['toggle'],
    props: {
      patchEvent: {
        type: Object,
        required: true,
      },
    },
    components: {
      Tag,
      Tooltip,
      ChevronIcon,
      PatchEventDetails,
    },
    setup(props) {
      const { titleCase, formatTimestamp, truncateAddress } = useFilters()
      return { titleCase, formatTimestamp, truncateAddress }
    },
    data() {
      return {
        showDetails: false,
      }
    },
    methods: {
      toggleDetails() {
        this.showDetails = !this.showDetails
        this.$emit('toggle', this.showDetails)
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .signature-name
    @apply font-mono

    :deep(.tag)
      @apply mr-2
      @apply align-baseline

</style>
