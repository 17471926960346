<template>

  <template v-if="isUserDataLoading || isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <template v-if="paginationList.totalItems === 0 && !paginationList.hasFiltersOrQueryApplied">
      <EmptyNotice>
        <template #image>
          <img src="@/assets/images/telescope.png" />
        </template>
        <template #title>
          There's nothing to see here.
        </template>
        <template #message>
          Once any of your contracts emit events, you’ll see them here.
        </template>
      </EmptyNotice>
    </template>

    <template v-else>

      <PaginationFilters :filterFormName="filterFormName" :paginationListName="paginationListName" />

      <template v-if="paginationList.totalItems === 0">
        <div class="relative">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <EmptyNotice :showActionButton="false" :showPatchTemplates="false">
            <template #image>
              <img src="@/assets/images/astronaut-sad.png" />
            </template>
            <template #title>
              Sorry, nothing matches your search.
            </template>
            <template #message>
              We couldn't find any contract events matching your search. Please try again.
            </template>
            <template #extra>
              <button class="link mt-4" @click="resetFilterForm">Clear search</button>
            </template>
          </EmptyNotice>
        </div>
      </template>

      <template v-else>
        <div class="table-container">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <div class="table user-contract-events-table">
            <div class="table-header table-row">
              <div class="small"></div>
              <div>CONTRACT</div>
              <div>EVENT / FUNCTION</div>
              <div>NETWORK</div>
              <div>TYPE</div>
              <div>ADDRESS</div>
              <div class="timestamp">TIMESTAMP</div>
            </div>
            <UserContractEventHistoryTableRow
              :key="patchEvent.id"
              :patchEvent="patchEvent"
              @toggle="detailsToggled"
              v-for="patchEvent in paginationList.currentPage"
            />
          </div>
        </div>
        <PaginationButtons :paginationListName="paginationListName" />
      </template>

    </template>

  </template>

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'

  import usePaginationPolling from '@/composables/usePaginationPolling'
  import usePaginationFilters from '@/composables/usePaginationFilters'

  import EmptyNotice from '@/components/page/EmptyNotice.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import PaginationFilters from '@/components/utils/PaginationFilters.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'
  import UserContractEventHistoryTableRow from '@/components/tables/UserContractEventHistoryTableRow.vue'

  export default {
    components: {
      LoadingIcon,
      EmptyNotice,
      LoadingOverlay,
      PaginationFilters,
      PaginationButtons,
      UserContractEventHistoryTableRow,
    },
    setup() {

      const numDetailsOpen = ref(0)
      const paginationListName = ref('userContractEvents')
      const filterFormName = ref('userContractHistoryFiltersForm')

      const {
        filterForm,
        paginationList,
        resetFilterForm,
        numSelectedFilters,
      } = usePaginationFilters({ filterFormName, paginationListName })

      // @TODO: remove this when socket stuff is available
      const { detailsToggled } = usePaginationPolling({ paginationListName, numDetailsOpen })

      return {
        filterForm,
        detailsToggled,
        paginationList,
        filterFormName,
        resetFilterForm,
        paginationListName,
        numSelectedFilters,
      }

    },
    computed: {
      ...mapState('user', ['isUserDataLoading']),
      ...mapState('app', ['isBootstrapDataLoading']),
    },
    created() {
      this.$store.dispatch('pagination/RESET_PAGINATION', { listName: this.paginationListName })
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>
